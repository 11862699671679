// 首页标签商品集合
  <template>
  <el-row>
    <el-col :span="24" >
        <div  class="MainBox" v-for="(item, index) in ArrList" :key="index">
            <div class="TitBox"  v-if="item.productVOList.length>0"  :style="{'background':item.color}">
              
              <img v-if="item.tagName=='宝药堂专区'" :src="bytUrl" alt="">
              <img  v-if="item.tagName=='男科妇科'" :src="nkfkUrl" alt="">
              <img  v-if="item.tagName=='精品丸药'" :src="jpwyUrl" alt=""  >
              <img  v-if="item.tagName=='热销丸剂'" :src="rxwjUrl" alt=""  >
              <img  v-if="item.tagName=='新品专区'" :src="xpzqUrl" alt=""  >
              <h3>{{item.tagName}}</h3> 
               <span class="moreInfo"  @click="jumpPath(item.id)">更多 ></span>
            </div>
            <div  class="ConBox"  v-if="item.productVOList.length>0">
                <!-- <div class="imgWraper" @click="jumpPath(item.id)"  >
                    <img :src="item.backImage| defaultImage"  :alt="item.tagName">
                </div>  -->

                <!-- .slice(0,15) -->
                <Shopitem class="item" v-for="(i,index) in item.productVOList.slice(0,5)" :key="index" :info="i"></Shopitem>
                <div class="clear"></div>
            </div>
        </div>
    </el-col>
   
  </el-row>
</template>
<style lang="less" scoped>
@import '~style/index.less';
.MainBox{
  // padding-top:15px;

  &:last-child{
     margin-bottom:60px;
  }
  .TitBox{
    // border-left: 4px solid #333;
    // text-indent:10px;
    // font-weight: bold;
    // padding:0px 15px;
    // font-size:24px;
    // color: #333333;
    // line-height:40px;
    padding: 14px 20px;
    background: #fff;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    img {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
    h3 {
        float: left;
        margin-left: 10px;
        margin-right: 12px;
        color: #333;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
    }
    .moreInfo {
        position: absolute;
        right:20px;
         cursor: pointer;
            &:hover{
                color: #F43C38;
            }
         .fonts();
         color: #666;
    }
  }
  .ConBox{
     width:100%;
     margin-bottom:10px;
     .item {
           float: left;
           width: calc(20% - 12px);;
           padding-bottom:10px;
         
       }
       .item:nth-child(5n+0){
            margin-right: 0px;
        }
        .clear{
          clear:both
        }
      //  height:410px;
      //  .imgWraper{
      //    background: #FF4F4F;
      //    cursor: pointer;
      //      width: 221px;
      //      float: left;
      //       height:319px;
      //     margin-top: 15px;
      //     position: relative;
      //     cursor: pointer;
      //      img{
      //         width: 100%;
      //         height: 100%;
      //     }
      //  }
    
  }
}

</style>

<script>
import {getTagIndexPage} from "api/product.js";
const Shopitem=()=>import('components/index/shopItem.vue');
import Ad from 'assets/hot.png';
import bytUrl from 'assets/icons/byt.png';
import nkfkUrl from 'assets/icons/nkfk.png';
import jpwyUrl from 'assets/icons/jpwy.png';
import rxwjUrl from 'assets/icons/rxwj.png';
import xpzqUrl from 'assets/icons/xpzq.png';
export default {
    name:"TagIndexPageList",
    data(){
        return {
          bytUrl:bytUrl,
          nkfkUrl:nkfkUrl,
          jpwyUrl:jpwyUrl,
          rxwjUrl:rxwjUrl,
          xpzqUrl:xpzqUrl,
          colorList: ['#FFDFD0','#E5F1FF','#FEEEB6','#CEFFE7','#FFD0D0'],
             ListFlag:false,
            currentIndex:0,
            imgUrl: Ad,
            ArrList:''//商品信息
        }
    },
     components:{
        //  AdHeader,
        //  GoodsList,
         Shopitem
    },
    created() {
          getTagIndexPage().then((data)=>{
                      console.log(data.data.data)
                      this.ArrList=data.data.data

                      // appList 处理
                      this.ArrList.forEach((item, index) => {
                        item.color = this.colorList[index%4]
                      })
                })
    },
    methods:{
          jumpPath(itemId){
            // 跳标签列表页
                this.$router.push({name:"TagIdList",query:{tagId:itemId}})
          }
    },
   
    
};
</script>
  